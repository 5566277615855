<template>
	<div>
		<div class="sections">
			<section class="section section-actual">
				<div class="content">
					<div
						v-if="user"
						class="welcome"
					>
						<h2 class="section-title">
							{{ userData }}, привет!
						</h2>
						<BtnToBackOffice class-name="button-hollow" />
					</div>
					<div
						v-if="projects && projects.length"
						class="actual"
					>
						<div class="title-element">
							Актуальные проекты
						</div>
						<div class="slider-wrap">
							<VueSlickCarousel
								class="actual-slider"
								v-bind="sliderSettings"
							>
								<BaseCardProject
									v-for="item in projects"
									:key="item.id"
									:project="item"
								/>
							</VueSlickCarousel>
						</div>
					</div>
				</div>
			</section>
			<section
				class="section section-courses section-courses--short"
			>
				<div class="courses-header">
					<div class="content">
						<div class="courses-bar courses-bar--column">
							<h2 class="title-section">
								Методические материалы к курсам
							</h2>
							<div class="search-courses">
								<button class="search-courses__button" />
								<input
									v-model="search"
									type="text"
									class="search-courses__value"
									placeholder="Поиск"
									@input="searching"
								>
								<div class="search-courses__close" />
							</div>
						</div>
					</div>
				</div>
				<div class="content">
					<div class="courses">
						<BaseCardCourse
							v-for="item in courses"
							:key="item.id"
							:course="item"
						/>
					</div>
					<div
						v-if="isEmpty"
						class="courses"
					>
						Результатов нет
					</div>
				</div>
			</section>
			<section class="section section-jump">
				<div class="content">
					<a
						target="_blank"
						class="jump"
						@click="startTest"
					>
						<h3 class="title-section">Тест на профориентацию для школьников</h3>
						<img
							:src="'/img/icons/icon__link-jump.svg' | prodSrcImage"
							alt="Ссылка"
							class="jump__icon"
						>
					</a>
				</div>
			</section>
			<section
				v-if="events && events.length"
				class="section section-events"
			>
				<div class="content">
					<h2 class="title-section">
						Практика и мероприятия
					</h2>
					<VueSlickCarousel
						class="events-slider"
						v-bind="sliderSettings"
					>
						<BaseCardEvent
							v-for="project in events"
							:key="project.id"
							:project="project"
						/>
					</VueSlickCarousel>
				</div>
			</section>

			<!--			<section class="section section-intro">-->
			<!--				<div class="content">-->
			<!--					<div class="welcome">-->
			<!--						<div class="section-text">-->
			<!--							«Лифт в будущее» станет мощной поддержкой вашим ученикам на пути к успешной карьере. Ведь у нас есть профориентационные треки с методической поддержкой для учителей. Крутые курсы по развитию полезных навыков, онлайн и офлайн активности, тесты по оценке навыков и многое другое.-->
			<!--						</div>-->
			<!--					</div>-->
			<!--				</div>-->
			<!--			</section>-->

			<!--			<section class="section section-jump">-->
			<!--				<div class="content">-->
			<!--					<a-->
			<!--						class="jump"-->
			<!--					>-->
			<!--						<h3 class="title-section">Проводите занятия, получайте сертификаты</h3>-->
			<!--						<img-->
			<!--							:src="'/img/icons/icon__link-jump.svg' | prodSrcImage"-->
			<!--							alt="Ссылка"-->
			<!--							class="jump__icon"-->
			<!--						>-->
			<!--					</a>-->
			<!--				</div>-->
			<!--			</section>-->
			<section
				v-if="tests && tests.length"
				class="section section-tests"
			>
				<div class="content">
					<h2 class="title-section">
						Тесты
					</h2>
					<div class="tests">
						<div>
							<router-link
								:to="routeMindFormat"
								target="_blank"
								class="card-test"
							>
								<img
									:src="'/img/test/test_mindformat1.png' | prodSrcImage"
									alt="Фото"
									class="card-test__pic"
								>
								<div class="card-test__content">
									<div class="card-test__icon" />
									<div class="card-test__title">
										Тест для оценки потенциала и особенностей мышления
									</div>
								</div>
							</router-link>
						</div>
						<BaseCardTest
							v-for="test in tests"
							:key="test.id"
							:test="test"
						/>
					</div>
				</div>
			</section>
			<CatalogCourses
				:role="'Teacher'"
			/>
			<section class="section section-presentation">
				<div class="content">
					<a
						:href="'/media/presentation_for_teachers.pdf' | prodSrcImage"
						target="_blank"
						class="presentation"
					>
						<div class="presentation__title">Скачать презентацию о проекте</div>
						<img
							:src="'/img/icons/icon__download-cloud--white.svg' | prodSrcImage"
							alt="Иконка"
							class="presentation__icon"
						>
					</a>
				</div>
			</section>
		</div>
	</div>
</template>

<script>
import BaseCardProject from '@/components/BaseComponents/BaseCardProject';
import CatalogCourses from '@/components/BaseComponents/CatalogCourses';
import {mapActions, mapGetters} from 'vuex';
import BaseCardEvent from '@/components/BaseComponents/BaseCardEvent';
import BaseCardTest from '@/components/BaseComponents/BaseCardTest';
import BaseCardCourse from '@/components/BaseComponents/BaseCardCourse';
import {debounce} from '@/assets/scripts/common';
import BtnToBackOffice from '@/components/backoffice/BtnToBackOffice';

export default {
    name: 'Teacher',
    components: {
        BaseCardProject,
        CatalogCourses,
        BaseCardEvent,
        BaseCardCourse,
        BaseCardTest,
				BtnToBackOffice
    },
    data: () => ({
        search: '',
        isLoading: false,
        events: [],
        courses: [],
        isEmpty: false,
        sliderSettings: {
            arrows: true,
            dots: false,
            infinite: false,
            'slides-to-show': 4,
            'slides-to-scroll': 4,
            draggable: false,
            speed: 600,
            swipe: true,
            'touch-move': true,
            responsive: [
                {
                    breakpoint: 1099,
                    settings: {
                        slidesToShow: 2,
                        slidesToScroll: 2
                    }
                },
                {
                    breakpoint: 640,
                    settings: {
                        slidesToShow: 1,
                        slidesToScroll: 1
                    }
                }
            ]
        }
    }),
    computed: {
        ...mapGetters('user', {
            isLogged: 'isLoggedIn',
            token: 'userToken',
            user: 'userInfo',
        }),
        ...mapGetters('tb', {
            projects: 'getActualProjects'
        }),
        ...mapGetters('tst', {
            tests: 'getAllTests'
        }),
        userData() {
            return this.user && this.user.first_name
        }
    },
    created() {
        this.getEvents()
        this.getAllTests()
        this.getFilterCourses()
        this.getActualProjects({
            visible_to: 'Teacher'
        })
    },
    mounted() {
        this.$scrollTo(document.querySelector('header.header-role'))
        this.callDebounceWrapper =
            debounce(() => {
                let inputTime = new Date().getTime();
                setTimeout(() => {
                    let callTime = new Date().getTime();
                    if (callTime >= inputTime + 1000) this.getFilterCourses()
                }, 1000)
            }, 1000)
    },
    methods: {
        ...mapActions('tb', [
            'getSpecialProjects',
            'getAllCourses',
            'getActualProjects'
        ]),
        ...mapActions('tst', [
            'getAllTests'
        ]),
        startTest() {
            if (this.isLogged) {
                this.$store.dispatch('user/career', this.user.id).then(url => this.modalOpen('testing', url))
            } else {
                this.modalOpen('testing', 'https://intalant.ru/lift/lift/index')
            }
        },
        getFilterCourses() {
            this.isLoading = true
            this.getAllCourses({
                name: this.search,
                type: 'Курс',
                visible_to: 'Teacher',
                category: 'methodical-material'
            })
                .then(response => {
                    this.courses = response.results
                    if (this.courses.length === 0) this.isEmpty = true
                    this.isLoading = false

                })
        },
        searching() {
            this.callDebounceWrapper()
        },
        getEvents() {
            this.getSpecialProjects({
                visible_to: 'Teacher'
            })
                .then(response => {
                    response.forEach( item => this.events.push(item))
                })
                .then(() => {
                    this.getAllCourses({
                        visible_to: 'Teacher',
                        type: 'Курс',
                        category: 'course'
                    }).then(response => {
                        response.results.forEach( item => this.events.push(item))
                    })
                })
                .then(() => {
                    this.getAllCourses({
                        visible_to: 'Teacher',
                        type: 'Курс',
                        category: 'case'
                    }).then(response => {
                        response.results.forEach( item => this.events.push(item))
                    })
                })
        }
    }
}
</script>

<style lang="less">
@import '../../assets/styles/pages/catalog';
.section-text {
	margin-top: 15px;
}
</style>
