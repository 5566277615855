<template>
	<div>
		<div class="sections">
			<section class="section section-intro">
				<div class="content">
					<div class="welcome">
						<h2
							v-if="user"
							class="section-title"
						>
							{{ userData }}, привет!
						</h2>
						<BtnToBackOffice class-name="button-hollow" />
						<div class="section-text">
							«Лифт в будущее» — это не только увлекательная дорога по карьере от выбора профессии мечты до руководящей должности через полезные курсы, онлайн и офлайн активности, консультации HR и стажировки в топовых компаниях, но и возможность получать именную стипендию
						</div>
					</div>
					<Banner />
					<Program :role="'Student'" />
				</div>
			</section>
			<section
				v-if="projects && projects.length"
				class="section section-actual"
			>
				<div class="content">
					<div class="actual">
						<div class="title-element">
							Актуальные проекты
						</div>
						<div class="slider-wrap">
							<VueSlickCarousel
								class="actual-slider"
								v-bind="sliderSettings"
							>
								<BaseCardProject
									v-for="item in projects"
									:key="item.id"
									:project="item"
								/>
							</VueSlickCarousel>
						</div>
					</div>
				</div>
			</section>
			<CatalogCourses
				:role="'Student'"
			/>
			<section
				v-if="tests && tests.length"
				class="section section-tests"
			>
				<div class="content">
					<h2 class="title-section">
						Тесты
					</h2>
					<div class="tests">
						<div>
							<router-link
								:to="routeMindFormat"
								target="_blank"
								class="card-test"
							>
								<img
									:src="'/img/test/test_mindformat1.png' | prodSrcImage"
									alt="Фото"
									class="card-test__pic"
								>
								<div class="card-test__content">
									<div class="card-test__icon" />
									<div class="card-test__title">
										Тест для оценки потенциала и особенностей мышления
									</div>
								</div>
							</router-link>
						</div>
						<BaseCardTest
							v-for="test in tests"
							:key="test.id"
							:test="test"
						/>
					</div>
				</div>
			</section>
			<section
				v-if="events && events.length"
				class="section section-events"
			>
				<div class="content">
					<h2 class="title-section">
						Практика и мероприятия
					</h2>
					<VueSlickCarousel
						class="events-slider"
						v-bind="sliderSettings"
					>
						<BaseCardEvent
							v-for="project in events"
							:key="project.id"
							:project="project"
						/>
					</VueSlickCarousel>
				</div>
			</section>
			<section
				v-if="vacancies && vacancies.length"
				class="section section-job"
			>
				<div class="content">
					<h2 class="title-section">
						Стажировки и вакансии
					</h2>
					<div class="slider-wrap">
						<VueSlickCarousel
							class="job-slider"
							v-bind="vacanciesSliderSetting"
						>
							<div
								v-for="vacancy in vacancies"
								:key="vacancy.id"
							>
								<BaseCardVacancies
									:is-favorite="isFavorited(vacancy.id)"
									:is-favorite-vacancy="isFavorited(vacancy.id)"
									is-position-sidebar
									is-landing-role
									:vacancy="vacancy"
								/>
							</div>
						</VueSlickCarousel>
					</div>
					<div class="button-more">
						<router-link
							to="/vacancies"
							target="_blank"
							class="button"
						>
							Смотреть все вакансии
						</router-link>
					</div>
				</div>
			</section>
		</div>
	</div>
</template>

<script>
import BaseCardProject from '@/components/BaseComponents/BaseCardProject';
import CatalogCourses from '@/components/BaseComponents/CatalogCourses';
import {mapActions, mapGetters} from 'vuex';
import BaseCardEvent from '@/components/BaseComponents/BaseCardEvent';
import BaseCardTest from '@/components/BaseComponents/BaseCardTest';
import BaseCardVacancies from '@/components/BaseComponents/BaseCardVacancies';
import Banner from '@/pages/catalog/Banner';
import Program from '@/pages/catalog/Program';
import BtnToBackOffice from '@/components/backoffice/BtnToBackOffice';

export default {
    name: 'Student',
    components: {
        BaseCardProject,
        CatalogCourses,
        BaseCardEvent,
        BaseCardTest,
        BaseCardVacancies,
        Banner,
        Program,
        BtnToBackOffice,
    },
    data: () => ({
        isLoading: true,
        events: [],
        sliderSettings: {
            arrows: true,
            dots: false,
            infinite: false,
            'slides-to-show': 4,
            'slides-to-scroll': 4,
            draggable: false,
            speed: 600,
            swipe: true,
            'touch-move': true,
            responsive: [
                {
                    breakpoint: 1099,
                    settings: {
                        slidesToShow: 2,
                        slidesToScroll: 2
                    }
                },
                {
                    breakpoint: 640,
                    settings: {
                        slidesToShow: 1,
                        slidesToScroll: 1
                    }
                }
            ]
        },
        vacanciesSliderSetting: {
            arrows: true,
            dots: false,
            infinite: false,
            'slides-to-show': 2,
            'slides-to-scroll': 2,
            draggable: false,
            speed: 600,
            swipe: true,
            'touch-move': true,
            responsive: [
                {
                    breakpoint: 1099,
                    settings: {
                        slidesToShow: 2,
                        slidesToScroll: 2
                    }
                },
                {
                    breakpoint: 640,
                    settings: {
                        slidesToShow: 1,
                        slidesToScroll: 1
                    }
                }
            ]
        }
    }),
    computed: {
        ...mapGetters('user', {
            isLogged: 'isLoggedIn',
            user: 'userInfo',
            favoriteVacancies: 'favoriteVacancies'
        }),
        ...mapGetters('tb', {
            vacancies: 'getAllVacancies',
            projects: 'getActualProjects'
        }),
        ...mapGetters('tst', {
            tests: 'getAllTests'
        }),
        favoritesVacanciesId() {
            return this.favoriteVacancies.map((el) => {
                return el.id
            })
        },
        isFavorite() {
            return this.favoritesVacanciesId.includes(this.vacancy.id)
        },
        userData() {
            return this.user && this.user.first_name
        },
    },
    created() {
        this.getEvents()
        this.getAllTests()
        this.getAllVacancies({
            visible_to: 'Student'
        })
        this.getActualProjects({
            visible_to: 'Student'
        })
    },
    mounted() {
        this.$scrollTo(document.querySelector('header.header-role'))
    },
    methods: {
        ...mapActions('tb', [
            'getSpecialProjects',
            'getAllVacancies',
            'getAllCourses',
            'getActualProjects'
        ]),
        ...mapActions('tst', [
            'getAllTests'
        ]),
        startTest() {
            if (this.isLogged) {
                this.$store.dispatch('user/career', this.user.id).then(url => this.modalOpen('testing', url))
            } else {
                this.modalOpen('testing', 'https://intalant.ru/lift/lift/index')
            }
        },
        isFavorited(id) {
            return this.favoritesVacanciesId.includes(id)
        },
        getEvents() {
            this.getSpecialProjects({
                visible_to: 'Student'
            })
                .then(response => {
                    response.forEach( item => this.events.push(item))
                })
                .then(() => {
                    this.getAllCourses({
                        visible_to: 'Student',
                        type: 'Курс',
                        category: 'course'
                    }).then(response => {
                        response.results.forEach( item => this.events.push(item))
                    })
                })
                .then(() => {
                    this.getAllCourses({
                        visible_to: 'Student',
                        type: 'Курс',
                        category: 'case'
                    }).then(response => {
                        response.results.forEach( item => this.events.push(item))
                    })
                })
        }
    }
}
</script>

<style lang="less">
@import '../../assets/styles/pages/catalog';
.section-text {
	margin-top: 15px;
}
</style>
