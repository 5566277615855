<template>
	<section
		class="section section-courses section-courses-animated"
	>
		<div
			class="courses-header"
		>
			<div class="content">
				<div class="courses-bar">
					<h2
						v-if="role === 'Teacher'"
						class="title-section"
					>
						Полезные курсы <br>для вас
					</h2>
					<h2
						v-else
						class="title-section"
					>
						Курсы
					</h2>
					<div class="search-courses">
						<button class="search-courses__button" />
						<input
							v-model="search"
							type="text"
							class="search-courses__value"
							placeholder="Поиск"
							@input="searching"
						>
						<div class="search-courses__close" />
					</div>
				</div>
				<div class="tabs-list">
					<div
						v-for="section in sortedSections"
						:key="section.id"
						class="tab-wrap"
					>
						<a
							class="tab"
							:class="{'tab--active': isSelectedSection && selectedSection === section.id}"
							@click="getSelectedSection(section.id)"
						>
							{{ section.name }}
						</a>
					</div>
				</div>
			</div>
		</div>
		<div class="content">
			<div
				class="courses"
			>
				<BaseCardCourse
					v-for="item in courses"
					:key="item.id"
					:course="item"
				/>
				<div
					v-if="isEmpty"
					class="courses"
				>
					Результатов нет
				</div>
			</div>
		</div>
	</section>
</template>

<script>
import {mapActions, mapGetters} from 'vuex';
import BaseCardCourse from '@/components/BaseComponents/BaseCardCourse';
import {debounce} from '@/assets/scripts/common';
import $ from 'jquery';

export default {
    name: 'CatalogCourses',
    components: {
        BaseCardCourse
    },
    props: {
        role: {
            type: String,
            default: null
        }
    },
    data: () => ({
        isEmpty: false,
        isLoading: false,
        selectedSection: '',
        isSelectedSection: false,
        courses: [],
        search: '',
        sortedSections: []
    }),
    computed: {
        ...mapGetters('user', {
            isLogged: 'isLoggedIn',
            token: 'userToken',
            user: 'userInfo'
        }),
        ...mapGetters('tb', {
            sections: 'getAllSections'
        })
    },
    async created() {
        await this.getAllSections()
        this.getFilterCourses().then(() => this.getSortedSections())
    },
    mounted() {
        if (this.$route.path === '/platform') {
            $(window).scroll(function() {
                const coursesOffset = $('.section-courses-animated')?.offset()?.top - 89;
                const coursesHeight = $('.section-courses-animated')?.innerHeight();
                if ($(this).scrollTop() > coursesOffset && $(this).scrollTop() < coursesOffset + coursesHeight - 150) {
                    $('.courses-header').addClass('courses-header--scroll');
                }
                else{
                    $('.courses-header').removeClass('courses-header--scroll');
                }
            });
        }
        this.callDebounceWrapper =
            debounce(() => {
                let inputTime = new Date().getTime();
                setTimeout(() => {
                    let callTime = new Date().getTime();
                    this.selectedSection = ''
                    if (callTime >= inputTime + 1000) this.getFilterCourses()
                }, 1000)
            }, 1000)
    },
    methods: {
        ...mapActions('tb', [
            'getAllCourses',
            'getAllSections',
            'startCourse'
        ]),
        getFilterCourses() {
            this.isLoading = true
            return this.getAllCourses({
                section: this.selectedSection,
                visible_to: this.role,
                type: 'Курс',
                name: this.search,
				page_size: 500
            })
                .then(response => {
                    this.courses = response.results
                    if (this.courses.length === 0) this.isEmpty = true
                    else this.isEmpty = false
                    if (this.search) {
                        this.courses.forEach(course => {
                            course.section.forEach( item => {
                                this.sections.forEach(section => {
                                    if (section.name === item) {
                                        this.isSelectedSection = true
                                        this.selectedSection = section.id
                                    }
                                })
                            })
                        })
                    }
                })
            .finally(() => this.isLoading = false)
        },
        getSelectedSection (id) {
            this.selectedSection = id
            this.isSelectedSection = true
            this.search = ''
            this.getFilterCourses()
        },
        getSortedSections() {
            this.sections && this.sections.forEach(section => {
                if (this.courses.length) {
                    this.courses.forEach(course => {
                        course.section?.forEach( item => {
                            if (item === section.name) this.sortedSections.push(section)
                        })
                    })
                }
            })
            this.sortedSections = this.sortedSections.filter((v, i, a) => a.indexOf(v) === i)
                .sort((a, b) => b.position - a.position)
            this.courses.forEach(course => {
                course.section?.forEach( item => {
                    if (this.sortedSections[0].name === item) {
                        this.isSelectedSection = true
                        this.selectedSection = this.sortedSections[0].id
                    }
                })
            })
            this.getFilterCourses()
        },
        searching() {
            this.callDebounceWrapper()
        }
    }
}
</script>
