<template>
	<div>
		<router-link
			:to="test.landing_url ? link : `/tests/${test.id}`"
			target="_blank"
			class="card-test"
		>
			<img
				:src="test.image"
				alt="Фото"
				class="card-test__pic"
			>
			<div class="card-test__content">
				<div class="card-test__icon" />
				<!--				<div class="card-test__date">-->
				<!--					29 мая-->
				<!--				</div>-->
				<div class="card-test__title">
					{{ test.name }}
				</div>
			</div>
		</router-link>
	</div>
</template>

<script>
export default {
    name: 'BaseCardTest',
    props: {
        test: {
            type: Object,
            default: null
        }
    },
		computed: {
			link() {
				return new URL(this.test?.landing_url).pathname;
			},
		}
}
</script>

<style scoped>

</style>
