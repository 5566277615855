<template>
	<div
		v-if="tasks"
		class="intro"
	>
		<div class="title-element">
			Выполняй задания и наблюдай свой прогресс на пути к стипендии
		</div>
		<div class="intro__link">
			<router-link
				to="/stipendia"
				target="_blank"
				class="link"
			>
				Смотреть подробнее
				<img
					:src="'/img/icons/icon__link-outer.svg' | prodSrcImage"
					alt="Иконка"
				>
			</router-link>
		</div>
		<div class="intro__list">
			<div
				v-for="(task, index) in tasks"
				:key="index"
				class="achievement"
				@click="modalOpen('ach-program', {task, tasks, index})"
			>
				<img
					:src="task.icon"
					alt="Иконка"
					class="achievement__pic"
				>
				<div class="achievement__title">
					{{ task.title }}
				</div>
			</div>
		</div>
		<!--		<div class="progress">-->
		<!--			<div class="progress__track">-->
		<!--				<div-->
		<!--					class="progress__line"-->
		<!--					:style="'width:' + progressValue + '%;'"-->
		<!--				>-->
		<!--					{{ progressValue }}%-->
		<!--				</div>-->
		<!--			</div>-->
		<!--			<div class="progress__range">-->
		<!--				0%-->
		<!--			</div>-->
		<!--			<div class="progress__range">-->
		<!--				100%-->
		<!--			</div>-->
		<!--		</div>-->
	</div>
</template>

<script>
import {mapActions, mapGetters} from 'vuex';

export default {
    name: 'Program',
    props: {
        role: {
            type: String,
            default: null
        }
    },
    computed: {
        ...mapGetters('scholar', {
            tasks: 'getTasks',
            progressValue: 'getProgressValue'
        })
    },
    created() {
        this.getTasks('progress')
    },
    methods: {
        ...mapActions('scholar', {
            getTasks: 'getTasks'
        })
    }
}
</script>
