<template>
	<div class="banner banner--general">
		<div class="banner__content">
			<h2 class="title-mono">
				Тест
			</h2>
			<h1 class="title-section">
				Тест на определение формата мышления
			</h1>
			<p class="banner__text">
				Узнай формат своего мышления и выяви уникальные способности
				личности
			</p>
			<router-link
				:to="routeMindFormat"
				class="button"
			>
				Подробнее
			</router-link>
		</div>
		<img
			src="/img/test/test-1.png"
			alt="Фото"
			class="banner__pic"
		>
	</div>
</template>

<script>
export default {
	name: 'Banner'
};
</script>
