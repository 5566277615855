<template>
	<div>
		<div class="card-actual">
			<a
				:href="project.url"
				class="card-actual__pic"
				:style="project.image | bgrDynamicImageCover"
			>
				<div
					class="tag-card"
					:class="{'tag-card--orange': categoryOptions === 'Курс',
						'tag-card--green': categoryOptions === 'Практика',
						'tag-card--blue': categoryOptions === 'Тест' || categoryOptions === 'Конкурс',
						'tag-card--lightblue': categoryOptions === 'Кейс' || categoryOptions === 'Програма',
						'tag-card--peach': categoryOptions === 'Статья',
						'tag-card--light': categoryOptions === 'Мероприятие',
					}"
				>
					{{ categoryOptions }}
				</div>
			</a>
			<a
				:href="project.url"
				class="card-actual__title"
			>{{ project.name }}</a>
		</div>
	</div>
</template>

<script>
import {mapGetters} from 'vuex';

export default {
    name: 'BaseCardProject',
    props: {
        project: {
            type: Object,
            default: null
        }
    },
    computed: {
        ...mapGetters('cnst', {
            categories: 'getCategories'
        }),
        categoryOptions() {
            return this.categories.find(x => x.slug === this.project.category).value
        }
    }
}
</script>

<style scoped>

</style>
