<template>
	<div>
		<div
			v-if="project.url"
			class="card-event"
		>
			<a
				:href="project.url"
				target="_blank"
				class="card-event__pic"
				:style="project.image | bgrDynamicImageCover"
			>
				<div
					class="tag-card"
					:class="{'tag-card--green': project.category === 'special-project-practice',
						'tag-card--blue': project.category === 'special-project-survey' || project.category === 'special-project-contest',
						'tag-card--lightblue': project.category === 'special-project-case',
						'tag-card--light': project.category === 'special-project-event',
					}"
				>{{ project.category === 'special-project-event' ? 'Мероприятие' :
					project.category === 'special-project-practice' ? 'Практика':
					project.category === 'special-project-contest' ? 'Конкурс':
					project.category === 'special-project-survey' ? 'Тест': 'Кейс' }}</div>
			</a>
			<div class="card-event__content">
				<a
					:href="project.url"
					target="_blank"
					class="card-event__title"
				>{{ project.title }}</a>
				<a
					:href="project.url"
					target="_blank"
					class="link link--orange"
				>
					Подробнее
					<img
						:src="'/img/icons/icon__arrow-right--orange.svg' | prodSrcImage"
						alt="Иконка"
					>
				</a>
			</div>
		</div>
		<div
			v-else
			class="card-event"
		>
			<router-link
				target="_blank"
				:to="`/courses/${project.slug}-${project.id}`"
				class="card-event__pic"
				:style="project.image_course | bgrDynamicImageCover"
			>
				<div
					class="tag-card"
				>
					{{ project.category === 'case' ? 'Кейс' : 'Курс' }}
				</div>
			</router-link>
			<div class="card-event__content">
				<router-link
					target="_blank"
					:to="`/courses/${project.slug}-${project.id}`"
					class="card-event__title"
				>
					{{ project.name }}
				</router-link>
				<router-link
					target="_blank"
					:to="`/courses/${project.slug}-${project.id}`"
					class="link link--orange"
				>
					Подробнее
					<img
						:src="'/img/icons/icon__arrow-right--orange.svg' | prodSrcImage"
						alt="Иконка"
					>
				</router-link>
			</div>
		</div>
	</div>
</template>

<script>
export default {
    name: 'BaseCardEvent',
    props: {
        project: {
            type: Object,
            default: null
        }
    }
}
</script>

<style scoped>

</style>
