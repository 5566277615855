<template>
	<div>
		<component :is="catalogPage" />
	</div>
</template>

<script>
import {mapGetters} from 'vuex';
import School from '@/pages/catalog/School';
import Student from '@/pages/catalog/Student';
import Specialist from '@/pages/catalog/Specialist';
import Teacher from '@/pages/catalog/Teacher';

export default {
    name: 'Catalog',
    components: {
        School,
        Student,
        Specialist,
        Teacher
    },
    computed: {
        ...mapGetters('user', {
            isLogged: 'isLoggedIn',
            user: 'userInfo',
        }),
        catalogPage() {
            if (localStorage.getItem('userRole') === 'Schoolboy') return 'School'
            else if (localStorage.getItem('userRole') === 'Student') return 'Student'
            else if (localStorage.getItem('userRole') === 'Specialist') return 'Specialist'
            else if (localStorage.getItem('userRole') === 'Teacher') return 'Teacher'
            else return 'Student'
        },
    },
    mounted () {
        if (!this.isLogged) this.$router.push({name: 'Expert'})
    }
}
</script>
