var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[_c('div',{staticClass:"card-actual"},[_c('a',{staticClass:"card-actual__pic",style:(_vm._f("bgrDynamicImageCover")(_vm.project.image)),attrs:{"href":_vm.project.url}},[_c('div',{staticClass:"tag-card",class:{'tag-card--orange': _vm.categoryOptions === 'Курс',
					'tag-card--green': _vm.categoryOptions === 'Практика',
					'tag-card--blue': _vm.categoryOptions === 'Тест' || _vm.categoryOptions === 'Конкурс',
					'tag-card--lightblue': _vm.categoryOptions === 'Кейс' || _vm.categoryOptions === 'Програма',
					'tag-card--peach': _vm.categoryOptions === 'Статья',
					'tag-card--light': _vm.categoryOptions === 'Мероприятие',
				}},[_vm._v(" "+_vm._s(_vm.categoryOptions)+" ")])]),_c('a',{staticClass:"card-actual__title",attrs:{"href":_vm.project.url}},[_vm._v(_vm._s(_vm.project.name))])])])
}
var staticRenderFns = []

export { render, staticRenderFns }